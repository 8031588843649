import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Helmet } from 'react-helmet' 

export default function Layout(props) {
  
  return (
    <>
      <Helmet title="Lemmerliese" defer={false} htmlAttributes={{ lang : 'de' }}>
      </Helmet>
      <Header />
      <main>
        {props.children}
      </main>
      <Footer mainTitle={props.mainTitle}/>
    </>
  )
}
