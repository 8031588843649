import React from "react"
import { Link } from "gatsby"

import logo from "../images/logo.png"


export default function Head(props) {
  return (
    <header className="container">
      <div className="row no-gutters align-items-stretch">
        <div className="col-6">
          <div className="d-flex align-items-sm-end align-items-center ">
            <h1 className="px-2 py-4 m-0"><Link className="navbar-brand m-0" to="/"><img src={logo} alt="Lemmerliese" /></Link></h1>
            <h2 className="d-none d-md-inline-block pb-5 m-0">Mit Liebe handgemacht.</h2>
          </div>
        </div>
        <div className="col-6  d-flex align-items-sm-end align-items-center">
          <nav className="text-right text-md-left ml-md-n3  pd-0 pb-sm-5  m-0">
            <a href="mailto:info@lemmerliese.de?subject=Kontaktaufnahme">Kontakt</a><br />
{/*             <a href="https://www.etsy.com/de/shop/Lemmerliese" target="_blank" rel="noreferrer">Hier gemütlich einkaufen</a>
 */}          </nav>
        </div>
      </div>
    </header>
  )
}
