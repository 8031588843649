
import React from "react"
import { Link } from "gatsby"


export default function Footer(props) {


  return (
    <footer className="container">
      <div className="row">
        <div className="col-12 text-center">
          &copy; lemmerliese, 2021 | <Link to="/impressum" rel="nofollow">Impressum</Link>
        </div>
      </div>
    </footer>
  )
}

